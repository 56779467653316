var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container popularGoods" },
    [
      _vm.isShowEdit == false
        ? _c("div", { staticClass: "actions_part clearfix" }, [
            _c("div", { staticClass: "actions_wrap" }, [
              _c(
                "div",
                { staticClass: "display_ib mr10 mb10" },
                [
                  _c("span", { staticClass: "tag" }, [
                    _vm._v("物料编号/系列：")
                  ]),
                  _c("el-input", {
                    staticClass: "input_single w200 mr10",
                    attrs: { placeholder: "请输入" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchHandle($event)
                      }
                    },
                    model: {
                      value: _vm.materialCode,
                      callback: function($$v) {
                        _vm.materialCode =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "materialCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "display_ib mr10 mb10" },
                [
                  _c("span", { staticClass: "tag" }, [_vm._v("物料备注：")]),
                  _c("el-input", {
                    staticClass: "input_single w200 mr10",
                    attrs: { placeholder: "请输入" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchHandle($event)
                      }
                    },
                    model: {
                      value: _vm.materialRemark,
                      callback: function($$v) {
                        _vm.materialRemark =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "materialRemark"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "display_ib mr10 mb10" },
                [
                  _c("span", { staticClass: "tag" }, [_vm._v("供应商：")]),
                  _c(
                    "el-select",
                    {
                      staticClass: "select_single w200 mr10",
                      attrs: { filterable: "", placeholder: "请选择" },
                      on: { change: _vm.getMaterialRemarkList },
                      model: {
                        value: _vm.supplierCode,
                        callback: function($$v) {
                          _vm.supplierCode = $$v
                        },
                        expression: "supplierCode"
                      }
                    },
                    [
                      _c("el-option", {
                        key: "",
                        attrs: { label: "全部", value: "" }
                      }),
                      _vm._l(_vm.supplierList, function(item) {
                        return _c("el-option", {
                          key: item.supplierId,
                          attrs: {
                            label: item.supplierCode,
                            value: item.supplierId
                          }
                        })
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "actions_btn_wrap down t_right" },
              [
                _c("el-button", {
                  attrs: {
                    size: "small",
                    type: "primary",
                    icon: "el-icon-search",
                    circle: "",
                    title: "搜索"
                  },
                  on: { click: _vm.searchHandle }
                }),
                _c("el-button", {
                  attrs: {
                    size: "small",
                    type: "primary",
                    icon: "el-icon-plus",
                    circle: "",
                    title: "添加"
                  },
                  on: { click: _vm.addHandle }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "container_table table_part clearfix mt20 w100p" },
              [
                _c("singleTable", {
                  attrs: {
                    tableList: _vm.remarkList,
                    tableLeaderFieldsList: _vm.leaderFieldsList,
                    tableTailFieldsList: _vm.tailFieldsList,
                    tableFieldsList: _vm.fieldsList,
                    tableLoading: _vm.tableLoading
                  },
                  on: {
                    checkBoxHandle: _vm.checkBoxHandle,
                    popoverHandle: _vm.popoverHandle,
                    deleteHandle: _vm.deleteHandle,
                    editHandle: _vm.factoryupd
                  }
                }),
                _c(
                  "div",
                  {
                    staticClass: "paging",
                    staticStyle: {
                      "margin-top": "20px",
                      float: "right",
                      height: "30px"
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "block" },
                      [
                        _c("el-pagination", {
                          attrs: {
                            "current-page": _vm.currentPage,
                            "page-sizes": [10, 50],
                            "page-size": _vm.pageSize,
                            layout: "sizes,total, prev, pager, next",
                            total: this.total
                          },
                          on: {
                            "size-change": _vm.handleSizeChange,
                            "current-change": _vm.handleCurrentChange,
                            "update:currentPage": function($event) {
                              _vm.currentPage = $event
                            },
                            "update:current-page": function($event) {
                              _vm.currentPage = $event
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          ])
        : _c("div", { staticClass: "popularGoodsAdd" }, [
            _c("div", { staticClass: "actions_part clearfix" }, [
              _c(
                "div",
                { staticClass: "down fr" },
                [
                  _c("el-button", {
                    attrs: {
                      size: "small",
                      type: "primary",
                      icon: "el-icon-plus",
                      circle: "",
                      title: "添加"
                    },
                    on: {
                      click: function($event) {
                        return _vm.addChildHandle()
                      }
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              [
                _c("editTable", {
                  attrs: {
                    tableList: _vm.editRemarkData,
                    tableLeaderFieldsList: _vm.leaderFieldsList,
                    tableTailFieldsList: _vm.editTailFieldsList,
                    tableFieldsList: _vm.editFieldsList
                  },
                  on: {
                    deleteHandle: _vm.delChildHandle,
                    inputChange: _vm.inputChange,
                    datePickerChange: _vm.datePickerChange,
                    numberInputChange: _vm.numberInputChange
                  }
                }),
                _c(
                  "div",
                  { staticClass: "edit_btn_wrap mt10 fr" },
                  [
                    _c("el-button", { on: { click: _vm.cancelVisibleclick } }, [
                      _vm._v("取消")
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.addVisibleclick }
                      },
                      [_vm._v("保存")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "提示",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            visible: _vm.dialogdelVisible,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogdelVisible = $event
            }
          }
        },
        [
          _c("span", [_vm._v("确定删除该条数据?")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogdelVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.delVisibleclick()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }